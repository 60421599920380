import React, {useState} from 'react'
import Input from "./Input";
import Textarea from "./Textarea";
import Button from "./Button";

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');

    return (
        <form className={"c-contact-form l-flex l-flex--column"}
              name="general"
              data-netlify="true"
              data-netlify-honeypot="h-name"
              action="/contact/thanks"
              method="POST">

            <Input name={"name"}
                   value={name}
                   placeholder={"Name"}
                   onChange={(e) => setName(e.target.value)} />

            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="general" />

            <input type="hidden" name="h-name" />

            <div className={"c-contact-form--email-tel l-flex l-flex-space-between"}>
                <Input name={"email"}
                       placeholder={"E-mail"}
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}/>

                <Input name={"tel"}
                       placeholder={"Phone number"}
                       value={tel}
                       onChange={(e) => setTel(e.target.value)}/>
            </div>

            <Textarea rows={3} name="message" placeholder={"Enter your message"} />

            <Button type={"form"}>Send</Button>
        </form>
    )
};

export default ContactForm;
