import React from 'react'
import PropTypes from "prop-types";

const Textarea = ({name, rows = 1, placeholder}) => (
    <div className={"c-textarea"}>
        <textarea rows={rows}
                  name={name}
                  placeholder={placeholder}
                  id="message"
                  type="text">
        </textarea>
    </div>
);

Textarea.propTypes = {
    name: PropTypes.string,
    rows: PropTypes.number,
}

export default Textarea;
