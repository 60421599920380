const scrollToEle = (ele, offset = 100) => {
    if(!(ele instanceof Element)) {
        ele = document.querySelector(ele);
    }

    window.scrollTo({
        top: ele.offsetTop - offset,
        behavior: 'smooth'
    })
}

export {scrollToEle}
