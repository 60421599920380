import React from 'react'
import PropTypes from "prop-types";

const Avatar = ({type = 'text', name, placeholder, value = null, onChange = null}) => (
    <div className={"c-input"}>
        <input type={type}
               name={name}
               value={value}
               onChange={(e) => onChange ? onChange(e) : null}
               placeholder={placeholder} />
    </div>
);

Avatar.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
}

export default Avatar;
