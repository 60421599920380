import React from 'react'
import PropTypes from "prop-types";

const  Avatar = ({image, alt, width, height}) => (
    <img src={image}
         loading="lazy"
         style={{width:width ?? 'auto', height:height ?? 'auto'}}
         alt={alt}
         className={"c-avatar"}/>
);

Avatar.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
}

export default Avatar;
