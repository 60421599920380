import React, {useState} from 'react'
import PropTypes from "prop-types";

const Tag = ({label, active = false, onClick = () => {}}) => {
    const [isActive, setIsActive] = useState(active);

    const clickHandler = () => {
        const toggle = !isActive;

        setIsActive(toggle);

        onClick({
            'active' : toggle,
            'label' : label
        });
    };

    return (
        <span className={"c-tag"}
              onClick={clickHandler}
              data-active={isActive}>
        {label}
        </span>
    );
}

Tag.propTypes = {
    label: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func
}

export default Tag;
