import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { borderRadius: '5px' }
  const { alt = '', childImageSharp, image } = imageInfo;
  const extension = image ? image?.ext?.replace('.', '') : imageInfo.extension;
  const publicUrl = image ? image.publicURL : imageInfo.publicURL;

  if (image && image.childImageSharp) {
    return (
      <Img style={imageStyle} fluid={image.childImageSharp.fluid} alt={alt} />
    )
  }

  if (!childImageSharp && (extension === 'svg' || extension === 'gif')) {
    return <img style={imageStyle} src={publicUrl} alt={alt} loading="lazy" />
  }

  if (childImageSharp) {
    return <Img style={imageStyle} fluid={childImageSharp.fluid} alt={alt}  />
  }

  if (image && typeof image === 'string')
    return <img style={imageStyle} src={image} alt={alt} loading="lazy" />

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImage
