import React, {useRef, useState} from "react";
import SlickSlider from "../SlickSlider";
import PropTypes from "prop-types";

const HomeProjectsInfoSlider = ({project}) => {
    const ref = useRef();
    const [activeInfo, setActiveInfo] = useState(0);

    const settings = {
        arrows: false,
        swipe: false,
        draggable: false,
        fade: true
    };

    const gotoInfo = (i) => {
        ref.current.slickGoTo(i);
        setActiveInfo(i);
    }

    return (
        <div className={"c-home-projects--info-slider"}>
            <nav>
                <button className={"c-button " + (activeInfo === 0 ? 'active' : '')}
                        onClick={e => gotoInfo(0)}>
                    Description
                </button>

                <button className={"c-button " + (activeInfo === 1 ? 'active' : '')}
                        onClick={e => gotoInfo(1)}>
                    Objective
                </button>
            </nav>

            <SlickSlider settings={settings} slickRef={ref}>
                <span>{project.description}</span>
                <span>{project.objective}</span>
            </SlickSlider>
        </div>
    );
}

HomeProjectsInfoSlider.propTypes = {
    project: PropTypes.object,
}

export default HomeProjectsInfoSlider
