import React, {useRef, useState} from 'react'
import PropTypes from "prop-types";
import SlickSlider from "./SlickSlider";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Tag from "./Tag";
import HomeProjectsInfoSlider from "./HomeProjects/HomeProjectsInfoSlider";
import '../styles/components/home-projects.scss'

const Template = ({title, projects, activeProject, gotoProject, slickSettings, sliderRef}) => {

    const ProjectImages = (images) => {
        if(!images) return '';

        return (
            <div className={"c-home-projects--images"}>
                <div className={"c-home-projects--ratio"}>
                    <div>
                        <SlickSlider>
                            { images.map((image, i) => (
                                <PreviewCompatibleImage key={i}
                                                        imageInfo={image}/>
                            ))}
                        </SlickSlider>
                    </div>
                </div>
            </div>
        );
    }

    const Title= () => {
        if(title === null) return '';

        return (
            <p className={"c-home-projects--title copy-header copy-header--xl"}>{title}</p>
        );
    };

    return (
        <section id={"home-projects"}
                 className="c-home-projects l-component">
            {Title()}

            <nav className="l-flex l-flex--center-middle">
                {projects.map((project, i) => (
                    <button key={i}
                            className={"c-button " + (activeProject === i ? 'active' : '')}
                            onClick={e => gotoProject(i)}>
                        {project.title}
                    </button>
                ))}
            </nav>

            <div className={"l-container"}>
                <SlickSlider settings={slickSettings} slickRef={sliderRef}>
                    {projects.map((project, i) => (
                        <article key={i}>
                            <header>
                                <HomeProjectsInfoSlider project={project} />

                                <div className="c-home-projects--tags">
                                    {project.tech.map((tech, i) => (
                                        <Tag label={tech} key={i}/>
                                    ))}
                                </div>
                            </header>

                            {ProjectImages(project.Images)}
                        </article>
                    ))}
                </SlickSlider>
            </div>
        </section>
    );
};

const HomeProjects = ({title = null, projects}) => {
    const sliderRef = useRef();
    const [activeProject, setActiveProject] = useState(0);
    const slickSettings = {
        arrows:false,
        swipe:false,
        draggable: false
    };

    const gotoProject = (i) => {
        sliderRef.current.slickGoTo(i);
        setActiveProject(i);
    }

    return (<Template title={title}
                      projects={projects}
                      activeProject={activeProject}
                      gotoProject={gotoProject}
                      slickSettings={slickSettings}
                      sliderRef={sliderRef}/>);
}

HomeProjects.propTypes = {
    title: PropTypes.string,
    projects: PropTypes.array,
}

export default HomeProjects
