import React, {useRef} from 'react'
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Template = ({settings, children, slickRef}) => (
        <Slider {...settings} ref={slickRef}>
            {children}
        </Slider>
);

const SlickSlider = ({settings = {}, slickRef, children}) => {
    const defaultSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    let mergedSettings = {...defaultSettings, ...settings};

    return (
        <Template settings={mergedSettings}
                      children={children}
                      slickRef={slickRef} />
    );
}

SlickSlider.propTypes = {
    settings: PropTypes.object,
    slickRef: PropTypes.object
}

export default SlickSlider
